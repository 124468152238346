






















































import type { PropType } from 'vue';
import * as vr from '@/helpers/validation';
import { mapState } from 'vuex';
import { WITHDRAW_DECLINE_REASONS } from '@/helpers/withdrawRequestHelpers';
import { ApproveWithdrawRequestPayload } from '@/api/schema';

export default {
  name: 'WithdrawRequestDeclineDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    data: {
      type: Object as PropType<ApproveWithdrawRequestPayload>,
      default: (): ApproveWithdrawRequestPayload => {
        return {} as ApproveWithdrawRequestPayload;
      }
    }
  },
  data(): any {
    return {
      formIsValid: true,
      formData: {},
      WITHDRAW_DECLINE_REASONS
    };
  },
  computed: {
    ...mapState('WithdrawRequests', ['loading']),
    isCommentDisabled(): boolean {
      return this.formData?.declinedReason !== WITHDRAW_DECLINE_REASONS.OTHER;
    },
    rules(): any {
      return {
        comment: this.isCommentDisabled
          ? []
          : [vr.required, (v) => vr.minLength(v, 3)]
      };
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value(): void {
      this.$refs.form?.resetValidation();

      this.$nextTick(() => {
        this.formData = {
          ...this.data,
          comment: null,
          declinedReason: WITHDRAW_DECLINE_REASONS.AML
        };
      });
    }
  },
  methods: {
    submitHandler(): void {
      this.$refs.form?.validate();
      this.$nextTick((): void => {
        if (this.formIsValid) {
          this.$emit('submit', {
            ...this.formData,
            comment: this.isCommentDisabled ? null : this.formData.comment
          });
        }
      });
    }
  }
};
