import { DataTableHeader } from 'vuetify';
import { mapState } from 'vuex';
import { textOverflowMiddle } from '@/helpers/formatString';
import { roundNumber } from '@/helpers/roundNumber';
import { WithdrawRequestItem } from '@/api/schema';
import {
  WITHDRAW_DECLINE_REASONS,
  WITHDRAW_REQUEST_STATUSES,
  WithdrawDeclineReasonsArray
} from '@/helpers/withdrawRequestHelpers';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import VStatus from '@/components/VStatus.vue';
import { copyToClipBoard as addToClipBoard } from '@/helpers/copyToClipBoard';

export default {
  components: {
    Tooltip,
    VStatus
  },
  data(): any {
    return {
      WITHDRAW_REQUEST_STATUSES
    };
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Withdrawal Request ID',
          value: 'id'
        },
        {
          text: 'Creation Date',
          value: 'createdAt'
        },
        {
          text: 'Operator User ID',
          value: 'operatorUserId'
        },
        {
          text: `Token Amount, ${this.tokenSymbol}`,
          value: 'amount'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Wallet ID (BSC Network)',
          value: 'walletToWithdraw'
        }
      ].map((item) => ({
        ...item,
        class: ['withdraw-table__th']
      }));
    }
  },
  methods: {
    textOverflowMiddle,
    roundNumber,
    addToClipBoard,
    getStatusClass(item: WithdrawRequestItem): string {
      if (this.processing) {
        return 'blue--text';
      }

      switch (item.status) {
        case WITHDRAW_REQUEST_STATUSES.APPROVED:
        case WITHDRAW_REQUEST_STATUSES.SUCCESS:
          return 'success';
        case WITHDRAW_REQUEST_STATUSES.DECLINED:
        case WITHDRAW_REQUEST_STATUSES.FAILED:
          return 'error';
        default:
          return 'primary';
      }
    },
    getStatusHumanName(item: WithdrawRequestItem): string {
      if (this.processing) {
        return 'Processing...';
      }

      return (
        item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()
      );
    },
    getHumanDeclinedReason(item: WithdrawRequestItem): string {
      if (item.status === WITHDRAW_REQUEST_STATUSES.FAILED) {
        return 'Approve/decline operation failed';
      }

      let result = WithdrawDeclineReasonsArray.find(
        (v) => v.value === item.declinedReason
      )?.text;

      if (item.declinedReason === WITHDRAW_DECLINE_REASONS.OTHER) {
        if (item?.comments) {
          result = [...item.comments]
            ?.sort((a, b) => b.id - a.id)
            .find((comment) => comment.declinedReason)?.comment;
        } else {
          result = null;
        }
      }

      return result || '';
    }
  }
};
