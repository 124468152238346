




































































































import { mapActions, mapState, mapGetters } from 'vuex';
import {
  approveWithdrawRequest,
  commentWithdrawRequest
} from '@/api/WithdrawRequests';
import WithdrawRequestsTable from '@/views/WithdrawRequestsView/WithdrawRequestsTable.vue';
import * as vr from '@/helpers/validation';
import WithdrawRequestDeclineDialog from '@/views/WithdrawRequestsView/WithdrawRequestDeclineDialog.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { WITHDRAW_REQUEST_STATUSES } from '@/helpers/withdrawRequestHelpers';
import { ApproveWithdrawRequestPayload } from '@/api/schema';
import WithdrawRequestMixin from '@/views/WithdrawRequestsView/WithdrawRequestMixin';
import type { DataTableHeader } from 'vuetify';

export default {
  name: 'WithdrawRequestItemView',
  mixins: [WithdrawRequestMixin],
  components: {
    WithdrawRequestsTable,
    WithdrawRequestDeclineDialog,
    ConfirmationDialog
  },
  data(): any {
    return {
      newComment: null,
      formIsValid: false,
      declineDialog: false,
      declineDialogData: null,
      approveDialog: false,
      approveDialogData: null,
      processing: false
    };
  },
  computed: {
    ...mapState('WithdrawRequests', ['item', 'loading']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),
    operationId(): number {
      return +this.$route.params.operationId;
    },
    showButtons(): boolean {
      if (!this.$role.can.update('withdraw')) return false;

      const status = this.item?.status;

      return [WITHDRAW_REQUEST_STATUSES.CREATED].includes(status);
    },
    rules(): any {
      return {
        comment: [vr.required, (v) => vr.minLength(v, 3)]
      };
    },
    rows(): DataTableHeader[] {
      return [...this.headers].sort((a, b) =>
        a.value === 'amount' ? (b.value === 'id' ? 1 : -1) : 0
      );
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.loadItem();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('WithdrawRequests', ['getItem']),
    addComment(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick(async () => {
        if (this.formIsValid) {
          await commentWithdrawRequest({
            id: this.item.id,
            comment: this.newComment
          });
          await this.loadItem();
          this.newComment = null;
          form.reset();
        }
      });
    },
    backHandler(): void {
      this.$router.back();
    },
    async loadItem(): Promise<any> {
      return this.getItem({
        id: this.operationId
      });
    },
    closeDeclineDialog(): void {
      this.declineDialogData = null;
      this.declineDialog = false;
    },
    async declineDialogHandler(
      data: ApproveWithdrawRequestPayload
    ): Promise<void> {
      try {
        this.processing = true;
        this.closeDeclineDialog();
        await approveWithdrawRequest(data);
        await this.loadItem();
      } finally {
        this.processing = false;
      }
    },
    openDeclineDialog(): void {
      this.declineDialogData = {
        id: this.item.id,
        status: WITHDRAW_REQUEST_STATUSES.DECLINED
      };
      this.declineDialog = true;
    },
    closeApproveDialog(): void {
      this.approveDialogData = null;
      this.approveDialog = false;
    },
    async approveDialogHandler(): Promise<void> {
      try {
        this.processing = true;
        this.closeApproveDialog();
        await approveWithdrawRequest({
          id: this.item.id,
          status: WITHDRAW_REQUEST_STATUSES.APPROVED
        });
        await this.loadItem();
      } finally {
        this.processing = false;
      }
    },
    openApproveDialog(): void {
      this.approveDialogData = {
        header: `Are you sure to approve withdrawal request ${this.item.id}?`,
        okText: 'Confirm'
      };
      this.approveDialog = true;
    }
  }
};
