











































import type { PropType } from 'vue';
import type { VuetifyTableOptions, WithdrawRequestItem } from '@/api/schema';
import WithdrawRequestMixin from '@/views/WithdrawRequestsView/WithdrawRequestMixin';
export default {
  name: 'WithdrawRequestsTable',
  mixins: [WithdrawRequestMixin],
  props: {
    list: {
      type: Array,
      default: (): WithdrawRequestItem[] => []
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    pageData: {
      type: Object as PropType<VuetifyTableOptions>,
      default: null
    }
  },
  data(): any {
    return {
      currentOptions: {}
    };
  },
  watch: {
    pageData: {
      handler(val: VuetifyTableOptions): any {
        this.currentOptions = { ...val };
      },
      immediate: true,
      deep: true
    }
  }
};
