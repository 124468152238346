var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table-with-pagination',{staticClass:"withdraw-table",attrs:{"headers":_vm.headers,"items":_vm.list,"server-items-length":_vm.total,"disable-sort":"","options":_vm.currentOptions,"loading":_vm.loading || _vm.processing,"hide-default-footer":_vm.hidePagination},on:{"update:options":[function($event){_vm.currentOptions=$event},function($event){return _vm.$emit('update:options', $event)}],"click:row":function($event){return _vm.$emit('click:row', $event)}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.roundNumber(item.amount, 10)))]}},{key:"item.walletToWithdraw",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.textOverflowMiddle(item.walletToWithdraw, 7, 6) || '—')),(item.walletToWithdraw)?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.addToClipBoard(item.walletToWithdraw)}}},[_vm._v("mdi-content-copy")]):_vm._e()],1)]}},{key:"item.operatorUserId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.textOverflowMiddle(item.operatorUserId, 8, 10) || '—')),(item.operatorUserId)?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.addToClipBoard(item.operatorUserId)}}},[_vm._v("mdi-content-copy")]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('VStatus',{attrs:{"small":"","type":_vm.getStatusClass(item)}},[_vm._v(_vm._s(_vm.getStatusHumanName(item)))]),((item.status === _vm.WITHDRAW_REQUEST_STATUSES.DECLINED || item.status === _vm.WITHDRAW_REQUEST_STATUSES.FAILED) && _vm.getHumanDeclinedReason(item))?_c('Tooltip',{attrs:{"icon":"mdi-help-circle-outline","text":_vm.getHumanDeclinedReason(item)}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }